<template>
  <div>
    <div class="header-information">
      <div class="box-information-page">
        <h3>{{ information }}</h3>
        <div class="indicator"></div>
      </div>
    </div>
    
    <v-row>
      <v-col cols="12" md="3" sm="3" v-if="steps.step1 == true">
        <div v-if="steps.step1Default == true">
          <div class="card-step text-center">
            <h4 class="mb-4">Infome o seu e-mail cadastrado</h4>
            <v-form
              @submit.prevent="changePasswordEmail"
              v-model="valid"
              lazy-validation
              ref="form"
            >
              <v-text-field
                label="E-MAIL"
                outlined
                dense
                v-model="email"
                :rules="emailRules"
              ></v-text-field>
              <v-btn block depressed color="primary" type="submit" :loading="carregamento" :disabled="desativado"
                >CONTINUAR</v-btn
              >
            </v-form>
          </div>
        </div>
        <div v-if="steps.step1Default == false">
          <div class="card-step text-center">
            <v-icon></v-icon>
            <v-icon color="#2ecc71" size="30">mdi-email-check-outline</v-icon>
            <h4 class="mb-3 mt-3">{{ messageEmail }}</h4>
          </div>
        </div>
      </v-col>

      <v-col cols="12" md="3" sm="3" v-if="steps.step2 == true">
        <div v-if="steps.step2Default == true">
          <div class="card-step text-center">
            <h4 class="mb-4">Infome o código</h4>
            <v-form
              @submit.prevent="changePasswordCode"
              v-model="valid"
              lazy-validation
              ref="form"
            >
              <v-otp-input
                length="5"
                dense
                v-model="code"
                :rules="codeRules"
                :error="codeError"
              ></v-otp-input>
              <v-btn class="mt-4" block depressed color="primary" type="submit"
                >CONTINUAR</v-btn
              >
            </v-form>
          </div>
        </div>
        <div v-if="steps.step2Default == false">
          <div class="card-step text-center">
            <v-icon></v-icon>
            <v-icon color="#2ecc71" size="30">mdi-lock-check-outline</v-icon>
            <h4 class="mb-3 mt-3">{{ messageCode }}</h4>
          </div>
        </div>
      </v-col>

      <v-col cols="12" md="3" sm="3" v-if="steps.step3 == true">
        <div v-if="steps.step3Default == true">
          <div class="card-step text-center">
            <h4 class="mb-4">Nova senha</h4>
            <div class="d-flex">
              <v-text-field
                label="SENHA"
                outlined
                dense
                v-model="newpass"
                :error="passError"
                :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPass ? 'text' : 'password'"
                @click:append="showPass = !showPass"
                :rules="passRules"
              ></v-text-field>
              <div class="mr-1 ml-1"></div>
              <v-text-field
                label="CONFIRME A SENHA"
                outlined
                dense
                v-model="confirmNewpass"
                :error="passError"
                :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPass ? 'text' : 'password'"
                @click:append="showPass = !showPass"
                :rules="passRules"
              ></v-text-field>
            </div>
            <v-btn
              class="mt-1"
              block
              depressed
              color="success"
              @click="changePassword"
              >FINALIZAR</v-btn
            >
          </div>
        </div>
        <div v-if="steps.step3Default == false">
          <div class="card-step text-center">
            <v-icon></v-icon>
            <v-icon color="#2ecc71" size="30">mdi-security</v-icon>
            <h4 class="mb-3 mt-3">{{ messagePass }}</h4>
          </div>
        </div>
      </v-col>

      <v-col cols="12" md="3" sm="3" v-if="steps.step4 == true">
        <div class="card-step text-center">
          <v-icon color="#2ecc71">mdi-check-all</v-icon>
          <h4 class="mt-2 mb-2">Senha alterada!</h4>
          <span class="mb-2">Sua senha foi alterada com sucesso.</span>
        </div>
      </v-col>
    </v-row>

    <v-snackbar
      :timeout="3000"
      color="#c0392b"
      elevation="24"
      v-model="snackbar"
    >
      {{ snackbarMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="dark"
          small
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "@/axios";

export default {
  data() {
    return {
      valid: true,
      information: "Alterar senha",
      steps: {
        step1: true,
        step1Default: true,
        step2: false,
        step2Default: true,
        step3: false,
        step3Default: true,
        step4: false,
      },
      email: "",
      emailRules: [
        (v) => !!v || "O campo é obrigatório",
        (v) => /.+@.+\..+/.test(v) || "O e-mail não é válido.",
      ],
      code: "",
      codeError: false,
      codeRules: [(v) => !!v || "O campo é obrigatório"],

      showPass: false,
      newpass: "",
      passError: false,
      confirmNewpass: "",
      passRules: [
        v => !!v || 'O campo é obrigatório',
        v => (v && v.length > 5) || 'Mínimo de 6 caracteres', 
      ],
      
      messageEmail: '',
      messageCode: "",
      messagePass: "",

      snackbar: false,
      snackbarMessage: "",
      carregamento: false,
      desativado: false 
    };
  },

  methods: {
    changePasswordEmail() {
      if (this.$refs.form.validate() == true) {
        const dados = new Object();
        dados.email = this.email;

        this.carregamento = true
        this.desativado = true
        
        axios
          .post(`/forget-password`, dados)
          .then((response) => {
            this.steps.step1Default = false;
            this.messageEmail = response.data.message
            
            setTimeout(() => {
              this.steps.step2 = true;
            }, 1000);
          })
          .catch((error) => {
            console.log(error.response);
          });
      }
    },

    changePasswordCode() {
      if (this.$refs.form.validate() == true) {
        const dados = new Object();
        dados.email = this.email;
        dados.token = this.code;

        this.carregamento = true
        this.desativado = true

        if (dados.token.length == 5) {
          axios
            .post(`/valid-token`, dados)
            .then((response) => {
              this.steps.step2Default = false;
              this.messageCode = response.data.message
              
              setTimeout(() => {
                this.steps.step3 = true;
              }, 2000);
            })
            .catch((error) => {
              console.log(error.response);
            });
        } else {
          this.codeError = true;
          setTimeout(() => {
            this.codeError = false;
          }, 1000);
        }
      }
    },

    changePassword() {
      this.carregamento = true
      this.desativado = true

      if (this.newpass == this.confirmNewpass) {
        const dados = new Object();
        dados.email = this.email;
        dados.token = this.code;
        dados.password = this.newpass;

        axios.post(`/reset-password`, dados).then((response) => {
          this.steps.step3Default = false;
          this.messagePass = response.data.message

          setTimeout(() => {
            this.steps.step4 = true;
          }, 1000);
        });
      } else {
        this.carregamento = false
        this.desativado = false
        this.snackbarMessage = "Preencha os campos corretamente.";
        this.snackbar = true
        
        this.passError = true;
          setTimeout(() => {
            this.passError = false;
          }, 1000);
      }
    },
  },
};
</script>

<style scoped>
.card-step {
  background: #ffffff;
  border-radius: 10px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  min-height: 200px;
  padding: 25px;

  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>