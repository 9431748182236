<template>
  <v-row class="mainContainer">
    <v-col class="sectionLeft" cols="12" sm="12" md="9"></v-col>

    <v-col class="sectionRight" cols="12" sm="12" md="3">

      <div class="boxLogo">
        <img src="../../assets/logoHGRS.png" alt="" height="130px" width="200px"/>
        <div class="box-text-logo">
          <h3 class="logo-text-TI">HGRS</h3>
          <h3 class="logo-text-separador">|</h3>
          <h3 class="logo-text-sesab">SESAB</h3>
        </div>
      </div>

      <div class="select-login mb-4">
        <h5>Selecione um método de login</h5>
        <hr>
        <v-row class="mb-3 mt-3">
          <v-col cols="12" md="6" sm="6">
            <v-btn depressed outlined block color="primary" @click="trocarInputCPF">CPF</v-btn>
          </v-col>
          <v-col cols="12" md="6" sm="6">
            <v-btn depressed outlined block color="error" @click="trocarInputREDE">REDE</v-btn>
          </v-col>
        </v-row>
      </div>

      <v-form @submit.prevent="login"
        v-model="valid"
        lazy-validation
        ref="form">

        <v-alert type="error" v-model="exibirMensagem">
          <h6 class="alertaText">{{ mensagem }}</h6>
          <h6 class="alertaText">{{ errorCPF }}</h6>
          <h6 class="alertaText">{{ errorPassword }}</h6>
          <h6 class="alertaText">{{ errorCaptcha }}</h6>
        </v-alert>

        <div v-if="validacaoInput == true">
          <v-text-field
            class="rounded-lg"
            label="CPF"
            placeholder="Digite seu CPF"
            outlined
            dense
            v-model="cpf"
            :rules="cpfRules"
            required
            v-mask="'###.###.###-##'"
          ></v-text-field>
        </div>

        <div v-else>
          <v-text-field
            class="rounded-lg"
            label="Usuário de Rede"
            placeholder="Digite seu usuário de rede"
            outlined
            dense
            v-model="usuarioRede"
            :rules="redeRules"
            required
          ></v-text-field>
        </div>

        <v-text-field
          class="rounded-lg"
          label="Senha"
          placeholder="Digite sua senha"
          outlined
          dense
          :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPass ? 'text' : 'password'"
          @click:append="showPass = !showPass"
          v-model="password"
          :rules="passRules"
          required
        ></v-text-field>

        <div class="captchaGrid">

          <img :src="img" alt="">

          <div class="space"></div>
          
          <v-text-field
            class="rounded-lg"
            label="Captcha"
            placeholder="Digite o captcha"
            outlined
            dense
            v-model="captcha"
            :rules="captchaRules"
            required
          ></v-text-field>

          <div class="space"></div>

          <v-btn class="rounded-lg mb-5" color="black" out elevation="0" height="40px" @click="initialize()">
            <v-icon dense color="white">mdi-refresh</v-icon>
          </v-btn>

        </div>

        <v-btn type="submit" class="rounded-lg mb-5 text-none" block color="primary" elevation="1" large :loading="carregamento" :disabled="desativado"
          >{{ btnStatus }}</v-btn
        >
      </v-form>

      <ResetPassword />

    </v-col>
  </v-row>

</template>

<script>
import axios from 'axios'
import config from '../../config/config'
import ResetPassword from './ResetPassword.vue'

export default {
  data() {
    return {
      validacaoInput: true,
      valid: true,
      showPass: false,
      cpf: "",
      cpfRules: [
        v => !!v || 'O CPF é obrigatório',
        v => (v && v.length <= 14) || 'Você excedeu o tamanho máximo.',
      ],
      usuarioRede: "",
      redeRules: [
        v => !!v || 'O usuário é obrigatório',
      ],
      password: "",
      passRules: [
        v => !!v || 'A senha é obrigatória',
        v => (v && v.length <= 16) || 'Você excedeu o tamanho máximo.', 
      ],
      captcha: "",
      captchaRules: [
        v => !!v || 'O captcha é obrigatório',
        v => (v && v.length <= 4) || 'Você excedeu o tamanho máximo.', 
      ],
      key: "",
      img: "",
      btnStatus: "Entrar",
      carregamento: false, 
      desativado: false,
      exibirMensagem: false,
      mensagem: '',
      errorCPF: '',
      errorPassword: '',
      errorCaptcha: '',
    };
  },

  components: {
    ResetPassword
  },

  created(){
    this.initialize();
  },

  methods: {

    initialize () { 
      axios.get(`${config.captchaURL}`)
        .then((response) => {
          this.key = response.data.key
          this.img = response.data.img
        })
    },

    login(){
      this.$refs.form.validate()
      const newCPF = this.cpf.replace(/[^a-zA-Z0-9]/g, '');

      if(this.cpf == null || this.cpf  == '') {
        const dados = new Object();
          dados.login = this.usuarioRede
          dados.password = this.password;
          dados.captcha = this.captcha;
          dados.key = this.key;
          dados.device_name = "AccessToken"

          axios.post(`${config.apiURL}/login`, dados)
        .then(
          response => {
            this.carregamento = true
            this.desativado = true

            setTimeout(() => {
              this.carregamento = false
              this.desativado = false 

              this.btnStatus = response.data.message
              localStorage.setItem('token', response.data.token);
              setTimeout(() => {this.$router.push({ name: 'Dashboard' })}, 1000);
            }, 1500);
          })
        .catch((error) => {
          let errorLogin = error.response.data.details
          let errorCPF = error.response.data.details.cpf
          let errorPassword = error.response.data.details.password     
          let errorCaptcha = error.response.data.details.captcha

          errorCPF = this.percorerObjetoError(errorCPF)
          errorPassword = this.percorerObjetoError(errorPassword)
          errorCaptcha = this.percorerObjetoError(errorCaptcha)

          if(errorCPF || errorPassword || errorCaptcha != null) {
            this.errorCPF = errorCPF
            this.errorPassword = errorPassword
            this.errorCaptcha = errorCaptcha
          } else {
            this.mensagem = errorLogin
          }

          this.exibirMensagem = true
          
          setTimeout(() => {
            this.exibirMensagem = false
          }, 3000);

          this.captcha = ""
          this.initialize()
        })
      } else {
        const dados = new Object();
          dados.cpf = newCPF;
          dados.password = this.password;
          dados.captcha = this.captcha;
          dados.key = this.key;
          dados.device_name = "AccessToken"

          axios.post(`${config.apiURL}/login`, dados)
          .then(
            response => {
              console.log(response);
              this.carregamento = true
              this.desativado = true

              setTimeout(() => {
                this.carregamento = false
                this.desativado = false 

                this.btnStatus = response.data.message
                localStorage.setItem('token', response.data.token);
                setTimeout(() => {this.$router.push({ name: 'Dashboard' })}, 1000);
              }, 1500);
            })
          .catch((error) => {
            let errorLogin = error.response.data.details
            let errorCPF = error.response.data.details.cpf
            let errorPassword = error.response.data.details.password     
            let errorCaptcha = error.response.data.details.captcha

            errorCPF = this.percorerObjetoError(errorCPF)
            errorPassword = this.percorerObjetoError(errorPassword)
            errorCaptcha = this.percorerObjetoError(errorCaptcha)

            if(errorCPF || errorPassword || errorCaptcha != null) {
              this.errorCPF = errorCPF
              this.errorPassword = errorPassword
              this.errorCaptcha = errorCaptcha
            } else {
              this.mensagem = errorLogin
            }

            this.exibirMensagem = true
            
            setTimeout(() => {
              this.exibirMensagem = false
            }, 3000);

          this.captcha = ""
          this.initialize()
        })
      }
    },

    percorerObjetoError(valor) {
      if(valor) {
        Object.values(valor).map((value) => {
          valor = value
        });
      } 
      return valor
    },

    trocarInputCPF() {
      this.usuarioRede = ''
      this.validacaoInput = true
    },

    trocarInputREDE() {
      this.cpf = ''
      this.validacaoInput = false
    }
  }
};
</script>

<style scoped>
*{
    font-family: 'Poppins';
}

.box-text-logo {
  display: flex;
  align-items: center;

  margin-top: 10px;
}

.box-text-logo> h4 {
  font-size: 20px;
  color: #1f1f1f;
}

.box-text-logo> h3 {
  font-size: 16px;
  color: #000000;
}

.logo-text {
  font-family: "Baloo 2", cursive;
  font-weight: 400;
}

.logo-text-ti {
  font-family: "Baloo 2", cursive;
  font-weight: 800;
}

.logo-text-separador {
  font-family: "Baloo 2", cursive;
  font-weight: 400;
  margin: 0 5px;
}

.logo-text-sesab {
  font-family: "Baloo 2", cursive;
  font-weight: 400;
}

.select-login {
  width: 100%;
  text-align: center;
}

.select-login > h5 {
  font-family: "Baloo 2", cursive;
  font-weight: 400;
  font-size: 16px;
}

.mainContainer {
  margin: 0;
  height: 100vh;
  background-color: #d9afd9;
  background-image: linear-gradient(0deg, #d9afd9 0%, #97d9e1 100%);
}

.sectionLeft {
  background: #fafafa;
  background-image: url(../../assets/bgHGRS.png);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  height: 100%;
}

.sectionRight {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 35px;
  background: #fff;
}

.sectionRight > .boxLogo {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  margin-bottom: 20px;
}

.alertaText {
  font-size: 14px;
  font-weight: 500;
}

.captchaGrid{
  display: flex;
}

.captchaGrid > img{
  height: 40px;
  width: 100px;

  border: 1px solid transparent;
  border-radius: 8px;
}

.captchaGrid > .space{
  width: 10px;
}

/* Responsivo */
@media only screen and (max-width: 1380px) {
  .sectionLeft {
    flex: 0 0 70%;
    max-width: 70%;
  }

  .sectionRight {
    flex: 0 0 30%;
    max-width: 30%;
  }
}

@media only screen and (max-width: 960px) {
  .mainContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 40px;
  }
  .sectionLeft {
    display: none;
  }

  .sectionRight {
    min-width: 400px;
    border-radius: 10px;
  }
}
</style>
