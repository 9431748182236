<template>
  <div>
    <v-btn text @click="dialog = true">Esqueci minha senha</v-btn>

    <v-dialog
      v-model="dialog"
      max-width="1360x"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar height="0" elevation="0">
          <v-btn class="btn-close" fab x-small color="primary" @click="dialog = false">
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-container>
          <ChangePassword />
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ChangePassword from "../conta/ChangePassword-comp.vue";
export default {
  data() {
    return {
      dialog: false,
    };
  },
  components: {
    ChangePassword,
  },
};
</script>

<style>
.header-information {
  margin-bottom: 20px;
}

.header-information > .box-information-page {
  display: flex;
  flex-direction: column;
}

.header-information > .box-information-page > h3 {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 18px;
}

.header-information > .box-information-page > .indicator {
  height: 2px;
  width: 50px;
  border-radius: 5px;
  margin-right: 10px;
  background: -webkit-linear-gradient(
    160deg,
    rgb(3, 69, 211) 0%,
    rgb(15, 162, 207) 100%
  );
}

.btn-close {
    
    position: absolute;
    right: 40px;
    top: 20px;

}
</style>